module.exports = [{
      plugin: require('/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147386701-1","head":true,"anonymize":true,"pageTransitionDelay":0,"siteSpeedSampleRate":100},
    },{
      plugin: require('/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2601307649930098"},
    },{
      plugin: require('/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
