// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-blog-post-template-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/components/blog/blog-post-template.js" /* webpackChunkName: "component---src-components-blog-blog-post-template-js" */),
  "component---src-pages-404-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infopage-a-save-your-company-from-hackers-and-disasters-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/infopage/a/save-your-company-from-hackers-and-disasters.js" /* webpackChunkName: "component---src-pages-infopage-a-save-your-company-from-hackers-and-disasters-js" */),
  "component---src-pages-infopage-b-save-your-company-from-hackers-and-disasters-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/infopage/b/save-your-company-from-hackers-and-disasters.js" /* webpackChunkName: "component---src-pages-infopage-b-save-your-company-from-hackers-and-disasters-js" */),
  "component---src-pages-infopage-core-save-your-company-from-hackers-and-disasters-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/infopage/core/save-your-company-from-hackers-and-disasters.js" /* webpackChunkName: "component---src-pages-infopage-core-save-your-company-from-hackers-and-disasters-js" */),
  "component---src-pages-services-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("/mnt/c/Users/tnesb/Documents/Projects/SpecterTechSolutions.com-v2019/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

